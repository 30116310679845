/* You can add global styles to this file, and also import other style files */

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.general-card {
  margin        : 1rem 1rem 1rem;
  padding       : 1.5rem;
}

.center-spacer {
  flex: 1 1 auto;
}

.toolbar-subtitle {
  font-size: small;
}

.button-container-fix {
  height: auto !important;
}
